<template>
  <button
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left"
    @click="$emit('open')"
  >
    <div class="w-52">
      <div class="flex-shrink-0 font-medium flex items-center gap-1">
        <fw-icon-arrow-right class="w-5 h-5 text-gray-500" />
        <span>
          <span class="text-gray-500">{{ call.prefix }}</span>
          <span class="font-semibold">{{ call.code }}</span>
        </span>
      </div>
    </div>
    <div class="w-32 flex">
      <fw-tag :type="colors[call.state]" size="xs">
        {{ $t(`status.${call.state}`) }}
      </fw-tag>
    </div>
    <div class="flex-1 text-sm font-medium capitalize flex gap-3">
      <v-clamp v-if="call.title[language]" autoresize :max-lines="1">
        {{ call.title[language] }}
      </v-clamp>
      <span v-else class="text-sm text-gray-500">Título não definido</span>
    </div>
    <div class="w-52 text-xs text-gray-500 font-normal justify-end">
      <div>{{ $t('createdAt') }} {{ call.created_date | formatDateTime }}</div>
    </div>
  </button>
</template>

<script>
import { CALL_STATUS_TAG_COLORS } from '@/utils/index.js'

export default {
  props: {
    user: {
      type: Object,
    },
    call: {
      type: Object,
    },
  },

  data() {
    return {
      colors: CALL_STATUS_TAG_COLORS,
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em",
    "status": {
      "draft": "Rascunho",
      "published": "Publicado",
      "canceled": "Cancelado"
    }
  },
  "en": {
    "createdAt": "Created at",
    "status": {
      "draft": "Draft",
      "published": "Published",
      "canceled": "Cancelado"
    }
  }
}
</i18n>
