<template>
  <fw-panel :title="'Criar novo procedimento'">
    <div>
      <fw-label>Escolha o tipo de procedimento</fw-label>
      <b-select v-model="type" placeholder="Tipo de procedimento" expanded>
        <option v-for="option in typeOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </b-select>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="closeModal()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disable="false" class="w-28" @click.native="createCall()">
        {{ 'Criar' }}
      </fw-button>
    </div>
  </fw-panel>
</template>
<script>
export default {
  name: 'ModalChooseCallType',
  components: {},
  props: {},
  data() {
    return {
      type: 'scholarship',
      typeOptions: [
        {
          label: 'Apoio',
          value: 'scholarship',
        },
      ],
    }
  },
  methods: {
    createCall() {
      this.$emit('create-call', this.type)
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
